.Container{
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column; 
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Noto, sans-serif;
}
.container-fluid{
  box-sizing: border-box; 
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
}

.Navbar {
  position: relative;
  top: 0;
  background-color: #EAF6FF;
  padding: 10px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}
.Navbar-Heading {
  flex-grow: 1;
  text-align: center;
  font-size: 0.8rem;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Noto, sans-serif;
  color: #0056b3;
  font-weight: bold;
  font-style: inherit;
}

.Funscholar-Logo {
 margin-left: 10%;
 background: transparent;
}

.Funscholar-Logo img {
  border-radius: 50%;
  height: 50px;
  width: 90%;
  background: transparent;
}

.profile-container {
  display: flex;
  align-items: center; 
  cursor: pointer;
}

.profile-icon i {
  font-size: 30px;
}

.profile-popup {
  position: absolute;
  right: 20px; 
  top: 65px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
}

.profile-popup p {
  font-size: 16px;
  color: #333; 
}

.icon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.profile-popup button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.profile-popup button:hover {
  background-color: #0056b3;
}


.sidebar {
  background-color: #EAF6FF;
  width: 200px;
  transition: width 0.3s ease, margin-left 0.3s ease;
  overflow-y: auto;
  padding: 5px 10px;
  height: calc(100% - 110px);
  scrollbar-width: thin;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.sidebar-container {
  background-color: #EAF6FF;
}

.sidebar-collapsed {
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.collapsed-menu i {
  font-size: 23px;
  line-height: 2.5;
  transition: font-size 0.3s ease;
}

.sidebar .menu-item {
  padding: 2px 3px 0 5px;
  cursor: pointer;
  align-items: center;
  border-radius: 10px;
}


.submenu {
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 20%;
  
}

.submenu .submenu-item {
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.submenu .submenu-item:hover {
  background-color: #FB8500;
  color: white;
}

.subsubmenu {
  border-radius: 10px;
  padding: 5px 10px;
}

.subsubmenu .subsubmenu-item {
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.subsubmenu .subsubmenu-item:hover {
  background-color: #FB8500;
  color: white;
  cursor: pointer;
}

.sidebar-icon i:hover{
  color: #ccc;
}


/* Main Content (Right Pane) */
.Content {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Noto, sans-serif;
  flex: 1; 
  background: none;
  padding: 5px;
  border-radius: 18px;
  overflow-y: scroll;
  scrollbar-width: thin;
}




/* For large screens (desktops) */
@media (min-width: 1200px) {
  .container-fluid {
    height: 100vh;
  }

  .sidebar {
    width: 200px;
  }

  .Content {
    padding: 20px;
  }

  
}

/* For medium screens (tablets) */
@media (min-width: 768px) and (max-width: 1199px) {

  .sidebar {
    width: 200px; 
  }

  .Content {
    padding: 15px;
  }

}

/* For small screens (mobile) */
@media (max-width: 767px) {
  .Navbar {
    padding: 0;
  }
  .Navbar-Heading{
    font-size: 8px;
  }

  .Funscholar-Logo{
    margin-left: 10%;
  }

  .Funscholar-Logo img{
    height: 40px;
  }

  .profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
  }

  .profile-icon img{
    margin: 0;
  }

  .profile-icon i {
    font-size: 25px; 
  }

  .profile-popup {
    position: absolute;
    right: 0; 
    top: 70px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 80%; 
    max-width: 250px;  
    box-sizing: border-box;
  }

  .profile-popup p {
    margin: 0 0 8px;
    font-size: 14px;  /* Adjusted font size for mobile */
  }

  

  .sidebar {
    font-size: 10px;
    width: 150px;
    overflow-y: auto; 
    padding: 5px 5px;
    height: calc(100% - 100px);
    scrollbar-width: thin;
  }

  .sidebar-collapsed {
    width: 50px;
  }


  .sidebar-icon i {
    font-size: 10px;
  }

  .Content {
    padding: 5px;
    margin-bottom: 20px; 
  }


.sidebar-open {
  width: 200px; 
}

}


