.edit-module {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  width: 100%;
  max-width: 800px;
  margin: 3rem auto;
  padding: 1rem;
  border-radius: 10px;
  flex-wrap: wrap;
  gap: 15px;
}

.edit-module > div:nth-child(odd) > button {
  width: 200px;
  background-color:#F0C201;
  padding: 30px 20px;
  border-radius: 10px;
  font-weight: bold;
}

.edit-module > div:nth-child(even) > button {
  width: 200px;
  background-color: #F0C201;
  padding: 30px 20px;
  border-radius: 10px;
  font-weight: bold;
}

.edit-module > div:nth-child(3n) > button {
  width: 200px;
  background-color: #F0C201;
  padding: 30px 20px;
  border-radius: 10px;
  font-weight: bold;
}

.edit-module > div:nth-child(4n) > button {
  width: 200px;
  background-color: #F0C201; 
  padding: 30px 20px;
  border-radius: 10px;
  font-weight: bold;
}

.board-button span {
  margin-left: 5px; 
}

/* Responsive Design */
@media (max-width: 768px) {
  .edit-module {
    flex-direction: row; 
    flex-wrap: wrap;
    padding: 10px 20px;
  }

  .edit-module > div > button {
    width: 100%; 
    padding: 5px 10px; 
    font-size: 0.5rem;
  }

  .edit-module > div {
    width: 10%; 
    padding: 10px 5px;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .edit-module > div > button {
    padding: 5px 10px; 
    font-size: 0.8rem;
  }
  .edit-module > div {
    width: 50%; 
    padding: 10px 5px;
    margin-right: 120px;
  }
}