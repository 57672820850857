
.chapter-details {
  overflow-y: auto;
  margin-bottom: 100px;
}

.expanded-content {
  overflow-y: auto;  
}


.chapter-details h6 {
  text-align: center;
  font-size: 1rem;
  color: #333;
}

.Three-button {
  text-align: center;
  display: flex;                
  justify-content: space-evenly; 
  align-items: center;         
  margin: 20px 0;           
  gap: 15px;  
  flex-wrap: wrap;
}

.First-button, .Second-button, .Third-button, .Fourth-button, .Fifth-button, .Sixth-button{
  align-items: center;
  text-align: center;
  color: white;                
  border: none;                
  border-radius: 10px;        
  padding: 2px 10px;         
  font-size: 12px;            
  cursor: pointer;             
  transition: background-color 0.3s ease; 
}
.First-button{
  max-width: 150px;
  background-color: rgb(245, 56, 56); 
}
.Second-button{
  max-width: 150px;
  background-color: rgb(231, 79, 24);
}
.Third-button{
  max-width: 200px;
  background-color: rgb(43, 88, 235); 
}
.Fourth-button{
  max-width: 200px;
  background-color:rgb(70, 130, 180) ;
}
.Fifth-button{
  max-width: 200px;
  background-color: rgb(34, 139, 34);
}
.Sixth-button{
  max-width: 150px;
  background-color: rgb(128, 0, 128);
}
.Three-button i {
  font-size: 1rem;
  margin-right: 10px;
}

.topic-name {
  display: flex;
  align-items: center;
}

.topic-icons {
  display: flex;
  margin-left: auto;
}

.icon1, .icon2, .icon3, .icon4, .icon5, .icon6 {
  align-items: center;
  text-align: center;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.7rem;
  padding: 1px 5px;
  cursor: pointer;
  margin-left: 15px;
}

.icon1 {
  background-color: rgb(245, 56, 56);
}

.icon2 {
  background-color: rgb(231, 79, 24);
}

.icon3 {
  background-color: rgb(43, 88, 235);
}

.icon4 {
  background-color: rgb(70, 130, 180);
}

.icon5 {
  background-color: rgb(34, 139, 34); 
}

.icon6 {
  background-color: rgb(128, 0, 128);
}

.expanded-topic ul ul li {
  color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 10px 0;
}

.expanded-topic .Three-button {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-content h3 {
  margin: 0 0 20px;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.Close-btn {
  position: absolute;
  max-width: 70px;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #dc3545;
}

.modal-content .close-button:hover {
  color: #b02a35;
}

.edit-modal {
  position: fixed; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  background-color: white; 
  border-radius: 8px; 
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); 
  padding: 20px; 
  z-index: 1000; 
  width: 300px; 
  box-sizing: border-box; 
}

.edit-modal h3 {
  margin: 0 0 15px;
  font-size: 20px; 
  text-align: center; 
}

.edit-modal input {
  width: 100%; 
  padding: 10px; 
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px; 
}

.Topic-edit-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 700px;
  background: white;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-height: 80vh;
  overflow-y: auto;
}

.Topic-edit-modal-heading {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.Topic-edit-modal-form-group {
  margin-bottom: 5px;
}

.Topic-edit-modal-label {
  display: block;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 3px;
}

.Topic-edit-modal-input {
  width: 100%;
  padding: 3px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
}

.Topic-edit-modal-input:focus{
  border-color: #1890ff;
  outline: none;
  box-shadow: 0 0 5px rgba(24, 144, 255, 0.5);
}

.Topic-edit-modal-text {
  font-size: 13px;
  font-weight: 500;
  word-wrap: break-word;
}

.Topic-edit-modal-link {
  font-size: 10px;
  text-decoration: none;
}

.Topic-edit-modal-link:hover {
  color: #007bff;
  text-decoration: underline;
}
.Topic-edit-modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.close {
  position: absolute;
  top: 0;
  right: 15px;
  font-weight: bold;
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
}


.Submit-button {
  display: flex;
  align-items: center;
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  padding: 0.5em 1em 0.5em 1em;
  color: white;
  background: #ad5389;
  background: linear-gradient(
    0deg,
    rgba(20, 167, 62, 1) 0%,
    rgba(102, 247, 113, 1) 100%
  );
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em #14a73e98;
  letter-spacing: 0.05em;
  border-radius: 20em;
}


.Submit-button:hover {
  box-shadow: 0 0.5em 1.5em -0.5em #14a73e98;
}

.Submit-button:active {
  box-shadow: 0 0.3em 1em -0.5em #14a73e98;
}

.close:hover{
  color: #dd1a1a;
}

.chapter-details ul {
  list-style: none;
  padding: 0;
}

.Chapter-list {
  width: 100%;
  background-color: #00d18b;
  color: black;
  border: 1px solid #ccc;
  padding: 3px 25px;
  font-size: 0.9rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 10px;
}

.Chapter-list:hover {
  color: whitesmoke;
  background-color: #0056b3;
  
  .Edit-icon :hover{
    color: wheat;
  }
}

.chapter-details ul ul {
  padding-left: 20px;
  margin-top: 10px;
}

.chapter-details ul ul li {
  padding: 3px 15px;
  background-color: #94cfbc;
  border-radius: 4px;
  margin-bottom: 5px;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chapter-details ul ul li:hover {
  background-color: white;
}

.chapter-details ul ul li div {
  font-weight: 500;
}

.media-player {
  position: relative;
  max-height: 600px;
  overflow-y: auto;
  margin-top: 10px;
  background-color: #fff;
  border: 2px solid #ccc;
  padding: 8px;
  border-radius: 5px;
}

.media-player h3 {
  font-size: 1rem;
  color: #333;
  margin-bottom: 8px;
  text-align: center;
}

.media-player iframe {
  width: 100%;
  border-radius: 4px;
}

.ppt-viewer {
  position: relative;
  max-height: 800px;
  overflow-y: auto;
  margin-top: 10px;
  background-color: #fff;
  border: 2px solid #ccc;
  padding: 8px;
  border-radius: 5px;
}



@media (max-width: 768px) {
  .chapter-details {
    padding: 10px;
  }

  .chapter-details h6 {
    font-size: 0.5rem;
  }

  .Three-button {
    flex-direction: column;
    gap: 10px;
  }

  .First-button, .Second-button, .Third-button, .Fourth-button, .Fifth-button, .Sixth-button {
    max-width: 100%;
    font-size: 14px;
    padding: 5px 10px;
  }

  .Three-button i {
    font-size: 1rem;
    margin-right: 8px;
  }

  .topic-name {
    flex-direction: column;
    text-align: center;
  }

  .topic-icons {
    flex-direction: column;
  }

  .icon1, .icon2, .icon3, .icon4, .icon5, .icon6 {
    margin-left: 0;
    padding: 8px 10px;
  }

  .expanded-topic ul ul li {
    padding: 8px 12px;
    margin: 8px 0;
  }

  .modal-content {
    width: 90%;
    max-width: 90%;
  }

  .edit-modal {
    width: 90%;
  }

  .Submit-button {
    font-size: 14px;
    padding: 0.6em 1.2em;
  }

  .Chapter-list {
    font-size: 0.9rem;
    padding: 5px 15px;
  }

  .media-player, .ppt-viewer {
    max-width: 100%;
    padding: 5px;
    margin-top: 10px;
  }

  .media-player iframe {
    height: 200px;
  }

  .close {
    font-size: 25px;
    margin-bottom: 200px;
  }

  .modal-content input {
    padding: 8px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .chapter-details {
    padding: 5px;
  }

  .Three-button {
    flex-direction: column;
    gap: 8px;
    margin: 15px 0;
  }

  .First-button, .Second-button, .Third-button, .Fourth-button, .Fifth-button, .Sixth-button {
    padding: 8px 12px;
    font-size: 13px;
    max-width: 100%;
  }

  .modal-content input {
    padding: 8px;
  }

  .Submit-button {
    font-size: 13px;
    padding: 0.5em 1em;
  }

  .Chapter-list {
    padding: 5px 10px;
    font-size: 0.4rem;
  }

  .media-player iframe {
    height: 180px;
  }

  /* Adjust input fields in modals */
  .edit-modal input {
    padding: 8px;
    font-size: 13px;
  }

  .modal-content h3 {
    font-size: 1.2rem;
  }

  .topic-icons {
    gap: 5px;
    flex-direction: column;
    justify-content: center;
  }

  .icon1, .icon2, .icon3, .icon4, .icon5, .icon6 {
    padding: 2px 5px;
   
  }

  /* Adjust the layout of the content */
  .topic-name {
    flex-direction: column;
    align-items: center;
  }

  .modal-content {
    padding: 15px;
    max-width: 90%;
  }
}

