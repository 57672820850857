.subject-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; 
  justify-content: center;
  gap: 30px; 
  padding: 20px; 
  margin-top: 50px;
}

.subject-item { 
  flex: 1 1 calc(33.333% - 20px); 
  min-width: 100px; 
  max-width: 200px;
  transition: transform 0.2s; 
}

.subject-item:hover {
  transform: scale(1.05); 
}

.subject-button {
  width: 100%; 
  padding: 30px 20px;
  font-weight: bold; 
  background-color: #007bff;
  color: #ffffff; 
  border: none; 
  border-radius: 5px; 
  cursor: pointer;
  font-size: 16px; 
}

.subject-button:hover {
  background-color: #0056b3;
}
  
  
  .subject-info {
    display: flex; 
    flex-wrap: wrap; 
    margin-top: 10px; 
    gap: 20px;
  }
  
  .detail-item {
    flex: 1 1 250px; 
    padding: 10px;
  }
  
  /* Responsive styling for smaller screens */
  @media (max-width: 768px) {
    .subject-details {
      display: flex;
      flex-direction: row; 
      flex-wrap: wrap;
      padding: 20px;
      gap: 10px;
    }
    .subject-item{
      padding: 10px 30px;
    }
  
    .detail-item {
      width: 100%; 
    }
  }


  