.class-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; 
  justify-content: center;
  gap: 30px; 
  padding: 20px; 
}

.class-item {
  background-color: #f8f9fa; 
  border: 1px solid #dee2e6; 
  border-radius: 5px; 
  flex: 1 1 calc(33.333% - 20px); 
  min-width: 100px; 
  max-width: 200px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  transition: transform 0.2s; 
}

.class-item:hover {
  transform: scale(1.05); 
}

.class-button {
  width: 100%; 
  padding: 30px 20px;
  font-weight: bold; 
  background-color: #007bff;
  color: #ffffff; 
  border: none; 
  border-radius: 5px; 
  cursor: pointer;
  font-size: 16px; 
}

.class-button:hover {
  background-color: #0056b3;
}



/* Responsive Styles */
@media (max-width: 768px) { 
  .class-details {
    flex-direction: row;
    gap: 5px;
  }

  .class-item {
    flex: 1 1 100%; 
    margin-bottom: 10px; 
  }
}

@media (min-width: 769px) { 
  .class-item {
    flex: 1 1 calc(33.333% - 20px); 
  }
}
